import '@hopin-team/ui-theme';

import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withLocalizationProvider } from '@features/localization';
import ExtraSponsorTiersControls from '@features/sponsors-grid/extra-sponsors-tiers-controls';
import {
  SponsorsGrid as UISponsorsGrid,
  SponsorsLevelHeader as UISponsorsLevelHeader,
} from '@hopin-team/ui-sponsors-grid';
import * as Routes from '@routes';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { array, bool, object, string } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { openModal } from '@/redux/reducers/modal';
import {
  removeSponsorArea,
  removeSponsorLevel,
  selectSponsorAreasForSection,
  setSponsorAreaLevel,
  setSponsorAreas,
} from '@/redux/reducers/sponsor-areas';
import {
  selectors as sponsorSelectors,
  setSponsors as setAllSponsors,
} from '@/redux/reducers/sponsors';
import withReduxProvider from '@/redux/with-provider';

import { LEVELS, MODALS } from './constants';
import LocalesContext from './locales-context';
import SponsorModalContainer from './sponsor-modal-container';
import SponsorsLevel from './sponsors-level';

const ALL_LEVELS = [
  LEVELS.GOLD,
  LEVELS.SILVER,
  LEVELS.BRONZE,
  LEVELS.NOTIER,
  LEVELS.TIER4,
  LEVELS.TIER5,
  LEVELS.TIER6,
  LEVELS.TIER7,
  LEVELS.TIER8,
  LEVELS.TIER9,
  LEVELS.TIER10,
];

const DEFAULT_LEVELS = [
  LEVELS.GOLD,
  LEVELS.SILVER,
  LEVELS.BRONZE,
  LEVELS.NOTIER,
];

const HideLevelHeader = styled.div`
  ${props =>
    props.hide &&
    css`
      margin-top: 1rem;

      ${UISponsorsLevelHeader} h3 {
        display: none;
      }
    `}
`;

const normalizeSponsorArea = ({ id, level, position, section, sponsor }) => ({
  id,
  level,
  position,
  section,
  sponsorId: sponsor.id,
});

const getSponsorsByLevel = level => sponsors =>
  sponsors
    .filter(sponsorArea => sponsorArea.level === level)
    .sort((a, b) => b.position - a.position);

/**
 * Gets the max sponsor area tier based on the available sponsorAreas
 * passed in to the method. Returns a minimum if it is larger than
 * max count discovered
 **/
function getSponsorAreaCountOrMinimum(sponsorAreas, section, minimum) {
  // assemble an object w/ keys as level tiers and arrays of sponsor areas as values
  const sponsorAreasByLevel = sponsorAreas.reduce((acc, curr) => {
    if (curr.section !== section) {
      return acc;
    }

    acc[curr.level] = acc[curr.level] || [];
    acc[curr.level].push(curr);
    return acc;
  }, {});

  // get the minimum sponsor level that contains sponsor areas, not empty sets
  const count = ALL_LEVELS.reduce((acc, curr, i) => {
    return sponsorAreasByLevel[curr] && sponsorAreasByLevel[curr].length > 0
      ? i + 1
      : acc;
  }, 0);

  return count > minimum ? count : minimum;
}

function SponsorsGrid({
  authToken,
  sponsorAreas: initialSponsorAreas,
  availableSponsors: initialSponsors = [],
  editable = false,
  sections,
  locales,
  eventSlug,
  hasCustomizedText = false,
  hasExtraSponsorTiers = false,
}) {
  // Auto-select first section
  const [section] = sections;

  const dispatch = useDispatch();

  useEffect(() => {
    // Put initial arguments in state, since this is the root react element
    dispatch(setAllSponsors(initialSponsors));
    dispatch(setSponsorAreas(initialSponsorAreas.map(normalizeSponsorArea)));
    // This should only run on first mounting of the component
  }, [dispatch, initialSponsorAreas, initialSponsors]);

  /** Simple count of the current "max" level of visible sponsors **/
  const [currentSponsorAreaMax, setCurrentSponsorAreaMax] = useState(
    getSponsorAreaCountOrMinimum(
      initialSponsorAreas.map(normalizeSponsorArea),
      section,
      DEFAULT_LEVELS.length,
    ),
  );

  const sponsors = useSelector(sponsorSelectors.selectAll);
  const sponsorAreas = useSelector(state => {
    const sponsorEntities = sponsorSelectors.selectEntities(state);

    // Add sponsor object on sponsorAreas for UI elements
    return selectSponsorAreasForSection(section)(state).map(i => ({
      ...i,
      sponsor: sponsorEntities[i.sponsorId],
    }));
  });

  /** Function to deduplicate an array */
  const dedupArray = array => [...new Set(array)];

  const levelsWithSponsors = sponsorAreas.reduce(
    (acc, cur) => dedupArray([...acc, cur.level]),
    [],
  );

  /** Callback to add sponsor areas past the default values / current max **/
  const handleAddSponsorArea = useCallback(
    event => {
      event.preventDefault();

      const sponsorLevelCount =
        (currentSponsorAreaMax > DEFAULT_LEVELS.length
          ? currentSponsorAreaMax
          : DEFAULT_LEVELS.length) + 1;

      setCurrentSponsorAreaMax(sponsorLevelCount);
    },
    [currentSponsorAreaMax, setCurrentSponsorAreaMax],
  );

  const handleRemoveSponsorTier = useCallback(
    event => {
      event.preventDefault();

      const removeBottomSponsorTier = () =>
        removeSponsorLevel(ALL_LEVELS[currentSponsorAreaMax - 1]);

      dispatch(removeBottomSponsorTier());

      // hide bottom tier in UI
      const sponsorLevelCount =
        currentSponsorAreaMax === DEFAULT_LEVELS.length
          ? DEFAULT_LEVELS.length
          : currentSponsorAreaMax - 1;

      setCurrentSponsorAreaMax(sponsorLevelCount);
    },
    [currentSponsorAreaMax, dispatch],
  );

  const lesserThanSponsorAreaMax = (_, levelIndex) =>
    levelIndex < currentSponsorAreaMax;

  const displayTierLevels = hasExtraSponsorTiers
    ? ALL_LEVELS.filter(lesserThanSponsorAreaMax)
    : DEFAULT_LEVELS;

  return (
    <UISponsorsGrid>
      <Alerts />
      <SponsorModalContainer
        authToken={authToken}
        eventSlug={eventSlug}
        section={section}
      />
      <HideLevelHeader
        hide={
          !editable && levelsWithSponsors.length === 1 // all sponsors are inside a single level
        }
      >
        {/*
          Make sure that at least a single value is send to the server, even if the list of sponsors is empty.
          Without that line it would be impossible to remove all existing areas to hide a sponsors grid.
        */}
        <input
          type="hidden"
          name={`sponsor_areas_attributes[0][_destroy]`}
          readOnly
          value="true"
        />

        <LocalesContext.Provider value={locales || {}}>
          {
            /**
             * If more than one section is provided, this is a result of
             * being on the combined registration/reception page, and
             * sponsorship areas will be duplicated across each section
             **/ sections.map(section =>
              sponsorAreas.map(({ id, level, position, sponsorId }) => (
                /**
                 * Key needs to include `level` because react-sortable
                 * is adding the item to the new list before removing it
                 * from the old one.
                 */
                <React.Fragment key={[section, id, level]}>
                  <input
                    type="hidden"
                    name={`sponsor_areas_attributes[${id}-${section}][level]`}
                    readOnly
                    value={level}
                  />
                  <input
                    type="hidden"
                    name={`sponsor_areas_attributes[${id}-${section}][position]`}
                    readOnly
                    value={position}
                  />
                  <input
                    type="hidden"
                    name={`sponsor_areas_attributes[${id}-${section}][sponsor_id]`}
                    readOnly
                    value={sponsorId}
                  />
                  <input
                    type="hidden"
                    name={`sponsor_areas_attributes[${id}-${section}][section]`}
                    readOnly
                    value={section}
                  />
                </React.Fragment>
              )),
            )
          }

          {
            /**
             * Only show levels that are within the current "max" visible level
             * and have actual sponsors, ending with either the "bronze" level,
             * the last sponsor area with an entered sponsor, or the last new
             * sponsor area created by clicking the add new sponsor area button
             **/
            displayTierLevels.map(level => {
              const levelSponsors = getSponsorsByLevel(level)(sponsorAreas);

              return (
                (editable || levelSponsors.length > 0) && (
                  <SponsorsLevel
                    key={level}
                    level={level == 'no_tier' ? '' : level}
                    locales={locales}
                    onAddSponsor={() => {
                      // If there are no sponsors, open the create sponsors modal
                      const modalToOpen =
                        sponsors.length === 0
                          ? MODALS.CREATE_AND_ADD_SPONSOR
                          : MODALS.ADD_EXISTING_SPONSORS;

                      dispatch(openModal(modalToOpen, { level }));
                    }}
                    onDeleteSponsor={sponsorArea => {
                      dispatch(removeSponsorArea(sponsorArea.id));
                    }}
                    sponsorAreas={levelSponsors}
                    onReorder={items => {
                      dispatch(setSponsorAreaLevel({ level, section, items }));
                    }}
                    editable={editable}
                  />
                )
              );
            })
          }
          {editable && hasExtraSponsorTiers && (
            <ExtraSponsorTiersControls
              hasCustomizedText={hasCustomizedText}
              textCustomizationHref={`${Routes.textOrganisersEventPath(
                eventSlug,
              )}#sponsors`}
              currentSponsorAreaMax={currentSponsorAreaMax}
              handleAddSponsorArea={handleAddSponsorArea}
              handleRemoveSponsorTier={handleRemoveSponsorTier}
              defaultLevelsLength={DEFAULT_LEVELS.length}
            />
          )}
        </LocalesContext.Provider>
      </HideLevelHeader>
    </UISponsorsGrid>
  );
}

export default compose(
  withAlertsProvider,
  withErrorHandling({ loggerName: LOGGER_NAMES.SPONSORS_GRID }),
  withLocalizationProvider,
  withReduxProvider,
)(SponsorsGrid);

SponsorsGrid.propTypes = {
  authToken: string.isRequired,
  sponsorAreas: array.isRequired,
  locales: object,
  availableSponsors: array,
  editable: bool,
  sections: array,
  eventSlug: string.isRequired,
  hasExtraSponsorTiers: bool,
  hasCustomizedText: bool.isRequired,
};
